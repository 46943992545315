import React, { Component } from 'react';
import firebase from 'firebase';
import MaterialTable from "material-table";
import _ from 'lodash';

import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';

// For add form
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



export default class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      questionData: {},
      convertData:{},
      isAddNew: false,
      uid:'',
      isPastest: false,
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ uid: user.uid })
        this.getQuizData(user.uid);
      }
    });
  }

  getQuizData = (uid) => {
    if (uid !== ''){
      let ref = firebase.database().ref(`users/${uid}/quiz/questions`);
        ref.on('value', snapshot => {
          if (snapshot.val()!== undefined && snapshot.val()!== null)
          {
            const data = Object.entries(snapshot.val()).map(e => Object.assign(e[1], { id: e[0] }));
            console.log('DATA RETRIEVED', data);
            this.setState({ data: data });
          }
          else{
            this.setState({ data: [] });
          }
        });
    }
  }
  

  render() {
    var data = this.state.data;
    //console.log('Table data', data);
    const tableIcons = {
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      Add: forwardRef((props, ref) => <Add {...props} ref={ref} />)
    };

    const handleClose = () => {
      this.setState({ isAddNew: false });
    };

    const handleConvertClose = () => {
      this.setState({isPastest:false });
    };

    const handleDelete = (questionData) => {
        var questionId = questionData.id;
        var uid = this.state.uid;
        console.log('questionId to be deleted',questionId, uid);
        firebase.database().ref(`users/${uid}/quiz/questions/${questionId}`).remove();
        firebase.database().ref(`users/${uid}/quiz/list/${questionId}`).remove();

    };

    const handleSave = () => {
      // Save an edit question
      var questionData = this.state.questionData;
      if (isQuestionValid(questionData)) {
        // Save this question back to the firebase
        var questionId = questionData.id;
        var uid = this.state.uid;
        if (questionId == undefined) {
          // New question
          questionId = firebase.database().ref(`users/${uid}/quiz/questions`).push().key;
          questionData.score = Math.floor(Math.random() * 100) + 1;
        }
        questionData.tableData = {};
        console.log('SAVE To Firebase', questionId, questionData);
        firebase.database().ref(`users/${uid}/quiz/questions/${questionId}`).update(questionData);
        firebase.database().ref(`users/${uid}/quiz/list/${questionId}`).update({ a: true });
        this.setState({ isAddNew: false });
      }
      else (
        // Question is invalid
        alert('Please filled in all required fields!')
      )


    };

    const isQuestionValid = (questionData) => {
      var {ques, ans, ans1, ans2, ans3} = questionData;
      var isQuestionValid = (ques !== undefined && ans !== undefined && ans1 !== undefined && ans2 !== undefined && ans3 !== undefined);
      return isQuestionValid
    };

    const handleChange = (event) => {
      // Update when question field is editted
      var questionDataTemp = this.state.questionData;
      var questionData = { ...questionDataTemp, [event.target.name]: event.target.value }
      console.log('questionData', questionData)
      this.setState({
        questionData: questionData,
      });

    };

    const handleConvertChange = (event) => {
      // Update when question field is editted
      var convertDataTemp = this.state.convertData;
      var convertData = { ...convertDataTemp, [event.target.name]: event.target.value }
      console.log('convertData', convertData)
      this.setState({
        convertData: convertData,
      });

    };

    const handleConvertSave = () => {
      // Do something about data before put it in a new question form
      // Get data from input
      
      var pastestData = document.createElement('span');
      pastestData.innerHTML = this.state.convertData.pastest;

      // Get the data
      var question = pastestData.getElementsByClassName("qText contextualClues")[0].innerText;
      console.log("Question", question);

      var ans = pastestData.getElementsByClassName("correct")[0].innerText;
      var options = [];
      var optionIndex = 0

      var explanation = '';
      for (var i = 0; i < 5; i++) {
        try{
          console.log("getElementsByClassName correct response",i, pastestData.getElementsByClassName(`correct response${i}`)[0].nextSibling.innerText);
          explanation = pastestData.getElementsByClassName(`correct response${i}`)[0].nextSibling.innerText;
          break;
        }
        catch{
        }
      }
     
      if (pastestData.getElementsByClassName("incorrect")[0]){
        options[optionIndex]=pastestData.getElementsByClassName("incorrect")[0].innerText;
        optionIndex = optionIndex + 1;
      }
      var incorrectList = pastestData.getElementsByClassName("col-xs-12 col-sm-12 col-md-12");
      for (var i = 0; i < incorrectList.length; i++) {
        if (incorrectList[i].className == 'col-xs-12 col-sm-12 col-md-12') {
         
          if (incorrectList[i].tagName == 'LABEL'){
            options[optionIndex] = incorrectList[i].innerText;
            optionIndex = optionIndex + 1;
          }
          else{
            console.log('incorrectList[i]',incorrectList[i]);
          }
         
        }
      }


      var questionData = {ans: ans,
      exp: explanation,
      ques: question.trim(),
      score: Math.floor(Math.random() * 100) + 1};
      for (var i = 0; i < options.length; i++) {
        questionData[`ans${i+1}`]=options[i];
      }


      console.log('questionData',questionData);
      this.setState({questionData:questionData, isAddNew:true, isPastest:false})

    };


    return (
      <div>

  <Dialog open={this.state.isPastest} aria-labelledby="max-width-dialog-title" onClose={handleConvertClose} fullwidth ="true" maxwidth = "700">
          <DialogTitle id="max-width-dialog-title">Content to be convert</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="standard-full-width"
              label="Copy text from pastest"
              name="pastest"
              multiline
              rowsMax="30"
              rows={15}
              fullWidth
              value={this.state.convertData.pastest}
              onChange={handleConvertChange}
            />
           
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConvertClose} color="primary">
              Cancel
        </Button>
            <Button onClick={handleConvertSave} color="primary">
              Save
        </Button>
          </DialogActions>
        </Dialog>


        <Dialog open={this.state.isAddNew} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit question</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="standard-multiline-flexible"
              label="Question"
              name="ques"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.ques}
              onChange={handleChange}
            />
            <TextField
              required
              id="standard-multiline-flexible"
              label="Answer"
              name="ans"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.ans}
              onChange={handleChange}
            />
            <TextField
              required
              id="standard-multiline-flexible"
              label="Option 1"
              name="ans1"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.ans1}
              onChange={handleChange}
            />
            <TextField
              required
              id="standard-multiline-flexible"
              label="Option 2"
              name="ans2"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.ans2}
              onChange={handleChange}
            />
            <TextField
              required
              id="standard-multiline-flexible"
              label="Option 3"
              name="ans3"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.ans3}
              onChange={handleChange}
            />
              <TextField
              required
              id="standard-multiline-flexible"
              label="Option 4"
              name="ans4"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.ans4}
              onChange={handleChange}
            />
            <TextField
              id="standard-multiline-flexible"
              label="Category"
              name="cat"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.cat}
              onChange={handleChange}
            />
            <TextField
              id="standard-multiline-flexible"
              label="Explanation"
              name="exp"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.questionData.exp}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
        </Button>
            <Button onClick={handleSave} color="primary">
              Save
        </Button>
          </DialogActions>
        </Dialog>



        <MaterialTable
          icons={tableIcons}
          title="List of quizzes"
          columns={[
            { title: 'Question', field: 'ques', width: '25%', cellStyle: { minWidth: 200 }},  
            { title: 'Answer', field: 'ans', cellStyle: {minWidth: 150}},
            { title: 'Option 1', field: 'ans1', cellStyle: {minWidth: 150} },
            { title: 'Option 2', field: 'ans2', cellStyle: {minWidth: 150} },
            { title: 'Option 3', field: 'ans3', cellStyle: {minWidth: 150} },
            { title: 'Option 4', field: 'ans4', cellStyle: {minWidth: 150} },
            { title: 'Category', field: 'cat', cellStyle: {minWidth: 50} },
            { title: 'Explaination', field: 'exp', cellStyle: {minWidth: 200}},
            { title: 'Score', field: 'score', type: 'numeric', cellStyle: {minWidth: 25} },
          ]}
          data={data}
          actions={[
            {
              icon: tableIcons['Add'],
              tooltip: 'Add new question',
              isFreeAction: true,
              onClick: (event) => {
                this.setState({ questionData: {}, isAddNew: true });
                }
            },

            {
              icon: tableIcons['Add'],
              tooltip: 'Add Pastest',
              isFreeAction: true,
              onClick: (event) => {
                this.setState({ convertData: {}, isPastest: true });
                }
            },

            
            {
              icon: tableIcons['Edit'],
              tooltip: 'Edit question',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var questionData = this.state.data[rowId];
                console.log('rowId', rowId, questionData);
                console.log('questionData', questionData);
                this.setState({ questionData: questionData, isAddNew: true });
              }
            },
            {
              icon: tableIcons['Delete'],
              tooltip: 'Delete a question',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var questionData = this.state.data[rowId];
                handleDelete(questionData);
              }

            }
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
            headerStyle: {
            backgroundColor: '#01579b',
            color: '#FFF',
            emptyRowsWhenPaging: false,
            //tableLayout: 'fixed',
          },
          

          }}
        />
      </div>
    );
  }
}
