import React, { Component } from 'react';
import firebase from 'firebase';
import MaterialTable from "material-table";
import _ from 'lodash';
import { Link } from 'react-router-dom';

// Quiz Icons
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';

// Flashcard Icons
import FolderOpen from '@material-ui/icons/FolderOpen';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import PostAddOutlined from '@material-ui/icons/PostAddOutlined';

// For add form
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AlertAddAlert } from 'material-ui/svg-icons';

export default class Cardsetlist extends Component {
  constructor(props) {
    super(props);


    this.state = {
      data: [],
      itemData: {},
      isAddNew: false,
      uid: '',
      isExpand: false,
      folderId: '',
      folderName: '',
    }
  }



  componentDidMount() {
    // Get folderId from the url
    const folderId = this.props.match.params.id;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ uid: user.uid, folderId: folderId })
        this.getFlashcardData(user.uid, folderId);
        console.log('You are login as', user.uid, folderId);
      }
    });
  }

  componentDidUpdate() {
  }


  getFlashcardData = (uid, folderId) => {
    // Get flashcards data based on folderid

    console.log('Load list of cardset in a folder data');
    let ref = firebase.database().ref(`users/${uid}/folder/${folderId}`);
    ref.on('value', snapshot => {
      if (snapshot.val() !== undefined && snapshot.val() !== null) {
        var dataFirebase = snapshot.val();
        console.log('RETRIEVE data', snapshot.val());
        dataFirebase['id'] = folderId;
        var data = this.convertToTableData([dataFirebase]);
        console.log('TABLE data', data);
        this.setState({ data: data, folderName: dataFirebase.name });
      }
    })
  }

  convertToTableData = (data) => {
    // Convert Firebase data to Tabledata format for display
    var newdata = [];
    data.forEach(folder => {
      console.log(folder);
      var cardsets = folder.cardsets;
      var folderId = folder.id;
      // Put id to the array
      if (cardsets !== undefined) {
        cardsets = Object.entries(cardsets).map(e =>
          Object.assign(e[1], { id: e[0] })
        );

        // Loop through cardsets to get data for the table
        cardsets.forEach(cardset => {
          var row = {
            id: cardset.id,
            name: cardset.name,
            number: cardset.Ncard,
            isFolder: false,
            folderId: folderId
          };
          newdata.push(row);
        });
      }
    });

    return newdata;
  }


  render() {
    const data = this.state.data;
    console.log('Table data', data);


    const tableIcons = {
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      FolderOpen: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      DescriptionOutlined: forwardRef((props, ref) => <DescriptionOutlined {...props} ref={ref} />),
      PostAddOutlined: forwardRef((props, ref) => <PostAddOutlined {...props} ref={ref} />),
    };

    const handleClose = () => {
      this.setState({ isAddNew: false });
    };

    const handleDelete = (itemData) => {

      console.log('DATA DELETE', itemData, this.state.data);
      var uid = this.state.uid;
      var cardsetId = itemData.id;
      var folderId = itemData.folderId;
      // New Ncardset update
      var Ncardset = this.state.data.length - 1;
      console.log('cardsetId to be deleted', folderId, cardsetId, itemData, Ncardset);
      firebase.database().ref(`users/${uid}/cardsets/${cardsetId}`).remove();
      firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}`).remove();
      firebase.database().ref(`users/${uid}/folder/${folderId}/Ncardset`).set(Ncardset);
    };

    const handleSave = () => {
      // Save an edit question
      var itemData = this.state.itemData;
      if (isItemValid(itemData)) {
        // Save this question back to the firebase
        // Based ont the itemData.id we know if it's exisitng one or new
        var uid = this.state.uid;
        var folderId = '';
        var cardsetId = '';
        cardsetId = itemData.id;
        folderId = itemData.folderId;
        if (cardsetId == undefined) {
          // New Cardset
          folderId = this.state.folderId; // Get folder id from the url not the item as this is not an edit
          var cardsetItem = { name: itemData.name, folderId: folderId, updateAt: new Date().getTime() };
          var folderCardsetItem = { name: itemData.name, updateAt: new Date().getTime() };
          var Ncardset = this.state.data.length + 1;

          cardsetId = firebase.database().ref(`users/${uid}/cardsets`).push().key;
          //console.log('cardsetItem', cardsetItem, cardsetId, folderId);
          firebase.database().ref(`users/${uid}/cardsets/${cardsetId}`).update(cardsetItem);
          firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}`).update(folderCardsetItem);
          firebase.database().ref(`users/${uid}/folder/${folderId}/Ncardset`).set(Ncardset);
        }
        else {
          // Edit cardset name
          firebase.database().ref(`users/${uid}/cardsets/${cardsetId}/name`).set(itemData.name);
          firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}/name`).set(itemData.name);
        }

        // Turn off the edit dialog
        this.setState({ isAddNew: false });
      }
      else (
        // Question is invalid
        alert('Please filled in all required fields!')
      )


    };

    const isItemValid = (itemData) => {
      var { name } = itemData;
      var isItemValid = (name !== undefined);
      return isItemValid
    };

    const handleChange = (event) => {
      // Update when question field is editted
      var itemDataTemp = this.state.itemData;
      var itemData = { ...itemDataTemp, [event.target.name]: event.target.value }
      console.log('itemData', itemData)
      this.setState({
        itemData: itemData,
      });

    };

    const handleRowClick = ((event, rowData) => {
      // Update when question field is editted
      var cardsetId = rowData.id;
      this.props.history.push({ pathname: `/cardlist/${cardsetId}`});
    });

    const linkSpacer = ' > ';

    return (
      <div>
        {/* Navigation between list of folders, list of cardsets and list of cards */}
        <div style={{ marginBottom: 20, fontSize: 20 }}>
          <Link to={{ pathname: '/flashcard' }}>Home</Link>
          {this.state.folderName !== '' ? `${linkSpacer}${this.state.folderName}` : ''}
        </div>

        <MaterialTable
          icons={tableIcons}
          localization={{ body:{ emptyDataSourceMessage:'There is no cardset in this folder, please select add new cardset to start'} }}
          title='List of cardsets'
          onRowClick={handleRowClick}
          data={data}
          columns={[
            { title: '', field: 'isFolder', width: 50, render: rowData => rowData.isFolder ? <FolderOpen /> : <DescriptionOutlined />},
            { title: 'Name', field: 'name', width: 850 },
            {title: 'Number of cards', field: 'number', width: 150,},
          ]}
          actions={[
            {
              icon: tableIcons['PostAddOutlined'],
              tooltip: 'Add new cardset',
              isFreeAction: true,
              onClick: (event) => {
                this.setState({ itemData: {}, isAddNew: true });
              }
            },

            {
              icon: tableIcons['Edit'],
              tooltip: 'Edit cardset name',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                console.log('rowId', rowId, itemData);
                console.log('itemData', itemData);
                this.setState({ itemData: itemData, isAddNew: true });
              }
            },
            {
              icon: tableIcons['Delete'],
              tooltip: 'Delete an item',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                this.setState({ itemData: itemData});
                handleDelete(itemData);
              }
            },
          ]}
          
          options={{
            //selection: true,
            actionsColumnIndex: -1,
            //defaultExpanded: this.state.isExpand,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
            tableLayout: 'fixed',
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF'
            }
          }}
        />

        {/* Pop up add and edit dialog */}
        <Dialog open={this.state.isAddNew} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.state.itemData.id == undefined ? 'Add new cardset' : 'Edit Cardset'}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="standard-multiline-flexible"
              label="Name"
              name="name"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.name}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
        </Button>
            <Button onClick={handleSave} color="primary">
              Save
        </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
